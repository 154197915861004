import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/common/PageHeader"
import Contact from "../components/pages/index/Contact"

const ContactPage = () => (
  <StaticQuery
    query={graphql`
      {
        contact: file(relativePath: { eq: "contact-us-hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        houston: file(relativePath: { eq: "houston.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="Contact Us"
          keywords={[`contact us`]}
          description="It's easy to reach us by phone, email, live chat or by simply submitting a form. Let us help you build a strategy for your next project."
        />
        <PageHeader
          image={data.contact.childImageSharp.gatsbyImageData}
          title="Contact Us"
          subTitle="Let's discuss how we build your vision together"
          formLocation="onPage"
        />
        <main>
          <Contact
            heading={
              <>
                Need a <span className="text-primary">custom built</span>{" "}
                application, MVP or website?
              </>
            }
          />
          <GatsbyImage
            image={data.houston.childImageSharp.gatsbyImageData}
            className="d-none d-md-block"
            alt="Houston"
          />
        </main>
      </Layout>
    )}
  />
)

export default ContactPage
